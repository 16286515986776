@use 'sass:math';
@mixin scrimGradient($startColor: #000000, $direction: 'to bottom') {

  $scrimCoordinates: (
          0: 1,
          19: 0.738,
          34: 0.541,
          47: 0.382,
          56.5: 0.278,
          65: 0.194,
          73: 0.126,
          80.2: 0.075,
          86.1: 0.042,
          91: 0.021,
          95.2: 0.008,
          98.2: 0.002,
          100: 0
  );

  $hue: hue($startColor);
  $saturation: saturation($startColor);
  $lightness: lightness($startColor);
  $stops: ();

  @each $colorStop, $alphaValue in $scrimCoordinates {
    $stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
    $stops: append($stops, $stop, comma);
  }

  background: linear-gradient(unquote($direction), $stops);

}

@function pxtorem($size) {
  $remSize: ($size/16);
  @return #{$remSize}rem;
}

@mixin aspect-ratio($arglist... /*$ratio or $width, $height*/){
  $map : keywords($arglist);
  $height: map-get($map, height) or nth-value($arglist, 2);
  $width: map-get($map, width) or nth-value($arglist, 1);
  $ratio: map-get($map, ratio) or if($width and $height, $width/$height, nth-value($arglist, 1)) or 1;
  $padding: 1/$ratio * 100%;
  &::before, &::after { content: ''; display: table; clear: both; width:1px; margin-left:-1px; }
  &::before { float: left; padding-bottom: $padding; }
}
// Helper function
// Return null rather than throwing an error if index is outside list range.
@function nth-value($list, $index) {
  @return if(length($list) >= $index, nth($list, $index), null);
}
