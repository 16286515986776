@import "reset";
@import "vars";
@import "font";
@import "breakpoints";
@import "typeface";

* {
     //border: 1px dashed #dcdcdc !important;
}
body {
     background-color: #edeef3;
     color: #333;
     overflow-x: hidden;
}
body, html {
     height: 100%;
}
.layout {
     min-height: 100%;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
}

.section {
     width: 100%;
     min-height: 200px;
     padding: pxtorem(50) pxtorem(20);

     &__inner {
          max-width: pxtorem(1280);
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
     }
     &:after {
          content: '';
          height: pxtorem(16);
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba(255,255,255,0.5);
     }
}
.main {
     margin-top: 50px;
}
.header {
     position: fixed;
     top: 0;
     z-index: 2;
     background-color: #16191b;
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;
     height: 50px;
     padding: 0 pxtorem(20);
     text-align: center;
     color: #ffffff;
     a {
          color: #fbfbfb;
          font-size: pxtorem(16);
          margin: 0  pxtorem(10);
          &:hover {
               color: #ffffff;
          }
     }
     &__left {
          display: flex;
          align-items: center;
          color: #ffffff;
          font-size: pxtorem(16);
     }
     &__logo {
          background-image: url("../assets/GEMMA_LOGO_WHITE.svg");
          height: 64px;
          width: 48px;
          background-repeat: no-repeat;
          background-position: center;
          margin-right: pxtorem(16);
          svg {
               path {
                    fill: #ffffff;
               }
          }
     }
     @include mobile() {
         span {
              display: none;
         }
     }
}
.splash {
     text-align: center;
     height: pxtorem(600);
     @include mobile() {
          height: pxtorem(400)
     }
     padding: 0;
     &__container {
          width: 100%;
          height: 100%;
          background-position: center top;
          position: relative;
          background-size: auto pxtorem(600);
          background-repeat: no-repeat;
          @include mobile() {
               background-size: auto 100%;
          }
     }
     &__image {

          img {
               //max-height: 600px;
               min-height: 600px;
               height: 100%;
          }
     }
     &__copy {
          position: absolute;
          top: 50%;
          left: 50%;
          color: #ffffff;
          font-size: pxtorem(32);
          text-align: left;
          transform: translateX(-25%) translateY(pxtorem(-250));
          @include mobile() {
               transform: translateX(pxtorem(-100)) translateY(pxtorem(-120));
          }
     }
     &__h1 {
          font-size: pxtorem(60);
          color: #ffffff;
          white-space: nowrap;
          @include mobile() {
               font-size: pxtorem(40);
          }
     }
     &__h2 {
          font-size: pxtorem(38);
          white-space: nowrap;
          color: #805347;
          @include mobile() {
               font-size: pxtorem(24);
          }
     }
     .bt {
          margin: 0 pxtorem(16);
          /*left: 50%;
          transform: translateX(-50%) translateY(10px);*/
     }
     .bt_container {
          display: flex;
          justify-content: flex-end;
          padding: pxtorem(32) 0;
          a {
               color: #ffffff;
          }
     }
}
.introcopy {
     display: flex;
     flex-direction: column;
     padding-top: pxtorem(0);
     position: relative;
     &__icon {
          background-image: url("../assets/logo.png");
          background-repeat: no-repeat;
          background-position: center;
          width: 64px;
          height: 64px;
          background-size: contain;
          margin-bottom: pxtorem(20);
     }
     text-align: center;
     &__inner {
          flex-direction: column;
          max-width: pxtorem(960);
     }
     h1 {
          font-size: pxtorem(48);
          margin-bottom: pxtorem(60);
          position: relative;
          &:after {
               content: '';
               width: pxtorem(46);
               height: pxtorem(46);
               background-image: url("../assets/sep.png");
               background-position: center;
               background-repeat: no-repeat;
               position: absolute;
               left: 50%;
               bottom: 0%;
               transform: translateY(100%);
          }
     }
}


.highlights {
     display: flex;
     &__col {
          flex-basis: 33%;
          height: 100%;
          align-items: stretch;
          text-align: right;
          @include mobile() {
               flex-basis: 100%;
          }
          &:nth-child(2) {
               display: flex;
               justify-content: center;
               img {
                    width: 100%;
                    margin-bottom: pxtorem(30);
               }
          }
          &:nth-child(3) {
               text-align: left;
               h4 {
                    text-align: left;
               }
               > div {
                    margin: pxtorem(0) pxtorem(60) pxtorem(40);
               }
          }
          > div {
               margin: pxtorem(0) pxtorem(60) pxtorem(40);
          }
     }
     &__entry {
          position: relative;
          /*&:after {
               content: '';
               width: 32px;
               height: 32px;
               position: absolute;
               background-color: $col-blue;
               top: -4px;
               transform: translateX(10px);
               border-radius: 50%;
          }*/
     }
     &__img {
          text-align: center;
          @include mobile() {
               display: none;
          }
     }
     &__inner {
          align-items: stretch;
          @include mobile() {
               flex-direction: column;
          }
     }
     &.section {
          background-color: #ffffff;
     }
}

.booknow {
     background-color: #bad2e2;
     position: relative;
     &__bg {
          pointer-events: none;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          opacity: 0.3;
          background-image: url("../assets/GEMMA_LOGO_WHITE_2.svg");
          background-size: pxtorem(1024) auto;
          background-position: center;
          background-repeat: no-repeat;
     }
     h1 {
          font-size: pxtorem(42);
     }
     &__inner {
          display: flex;
          flex-direction: column;
     }
     &__logo {
          //background-image: url("../assets/GEMMA_LOGO_WHITE_2.svg");
          height: 200px;
          width: 300px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          svg {
               path {
                    fill: #ffffff;
               }
          }
     }
}

.services {
     &__inner {
          flex-direction: column;
     }
     h1 {
          font-size: pxtorem(60);
     }
     &__container {
          max-width: pxtorem(1280);
          margin: auto;
          justify-content: center;
          align-items: center;
          display: flex;
          flex-wrap: wrap;
     }
     &__service {
          padding: pxtorem(10);
          text-align: center;
          a {
               padding: pxtorem(20);
               font-size: pxtorem(20);
               color: $col-primary;
               font-family: "poiret-one", sans-serif;
               font-weight: bold;
               @include mobile() {
                    padding: pxtorem(0);
               }
          }
          flex-basis: 33%;
          @include mobile() {
               flex-basis: 50%;
               display: flex;
               align-items: flex-start;
               height: 170px;
          }
          img {
               width: 100%;
               margin-bottom: pxtorem(12);
          }
     }
}
.quotes {
     background-color: #d06b96;
     position: relative;
     min-height: pxtorem(400);
     &__icon {
          background-image: url("../assets/ico_yoga.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          width: 64px;
          height: 64px;
     }
     &__bg {
          pointer-events: none;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          opacity: 0.3;
          //background-image: url("../assets/GEMMA_LOGO_WHITE_2.svg");
          background-size: pxtorem(1024) auto;
          background-position: center;
          background-repeat: no-repeat;
     }
     h1 {
          font-size: pxtorem(42);
     }
     &__inner {
          display: flex;
          flex-direction: column;
          position: relative;
     }
     &__entry {
          opacity: 1;
          top: 0;
          display: none;
          min-height: pxtorem(200);
          align-items: center;
          padding: 0 pxtorem(200);
          box-sizing: border-box;
          width: 100%;
          @include mobile() {
               padding: 0;
          }
          p {
               text-align: center;
               color: #ffffff;
          }
     }
}
.blog {
     background-color: #e5e2e5;
     position: relative;
     &__bg {
          pointer-events: none;
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          opacity: 0.3;
          background-image: url("../assets/GEMMA_LOGO_WHITE_2.svg");
          background-size: pxtorem(1024) auto;
          background-position: center;
          background-repeat: no-repeat;
     }
     h1 {
          font-size: pxtorem(42);
     }
     &__inner {
          display: flex;
          flex-direction: column;
     }
     &__logo {
          //background-image: url("../assets/GEMMA_LOGO_WHITE_2.svg");
          height: 200px;
          width: 300px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          svg {
               path {
                    fill: #ffffff;
               }
          }
     }
     &__entry {
          a {
               font-size: 1.25rem;
               color: #676767;
               font-family: "poiret-one", sans-serif;
               font-weight: bold;
          }
     }
}

.bt {
     width: pxtorem(80);
     height: pxtorem(80);
     border-radius: 50%;
     background-color: rgb(208, 107, 150);
     font-size: pxtorem(16);
     display: flex;
     align-items: center;
     justify-content: center;
     line-height: pxtorem(16);
     text-align: center;
     cursor: pointer;
     &--blue {
          background-color: $col-blue;
     }
     &--yellow {
          background-color: $col-yellow;
     }
     &--contact {
          color: #ffffff;
          font-weight: bold;
     }
     position: relative;
     &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          border-radius: 50%;
          border: 3px solid rgba(255, 255, 255, 0.3);
     }
     &:hover {
          &:after {
               border: 5px solid rgba(255, 255, 255, 0.3);
          }
     }
}



.article {
     width: 100%;
     min-height: 200px;
     padding: pxtorem(50) pxtorem(20);
     background-color: #ebecf2;
     &__inner {
          max-width: 960px;
          margin: auto;+
          img {
               margin: 0 auto pxtorem(32);
          }
          p {
               line-height: 1.5;
          }
     }
}
.column {
     display: flex;
     width: 100%;
     &__left {
          flex-grow: 1;
     }
     &__right {
          flex: 0 0 27%;
          //width: 25%;
          min-height: 500px;
          @include mobile() {
               display: none;
          }
     }
}

.headerimage {
     width: 100%;
     height: 200px;
     position: relative;
     overflow: hidden;
     padding: pxtorem(50) pxtorem(20);
     img {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-45%);
          //filter: brightness(1.3);
          filter: grayscale(100%) contrast(100%);
     }
     &:before {
          content: '';
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: pxtorem(16);
          background-color: rgba(255, 255, 255, 0.5);
     }
     &:after {
          content: '';
          width: 100%;
          height: 100%;
          //background-color: rgba(138, 141, 172, 0.6);
          //background-color: rgba(132, 133, 136, 0.6);
          background-color: rgba(127, 144, 195, 0.8);
          position: absolute;
          top: 0;
          left: 0;
     }
     h1 {
          position: absolute;
          z-index: 1;
          color: #ffffff;
          font-size: pxtorem(48);
          font-weight: bold;
          bottom: pxtorem(32);
     }
     position: relative;
     &__inner {
          max-width: 960px;
          margin: auto;
          display: flex;
          align-items: center;
          height: 100%;
     }
}
.copy {
     padding-right: pxtorem(70);
     @include mobile() {
          padding-right: pxtorem(0);
     }
     img {
          margin: pxtorem(20) auto;
     }
     h1 {
          font-size: pxtorem(32);
          margin-bottom: pxtorem(32);
          font-weight: bold;
     }
     ul {
          padding-left:  pxtorem(16);
          margin-bottom:  pxtorem(48);
     }
     ul, li {
          padding-left: pxtorem(16);
          margin-left: pxtorem(16);
     }
     li {
          list-style-type: disc;
     }

}
.sidebar {
     width: 100%;
     min-height: 100%;

     &__box {
          h1 {
               color: #ffffff;
               font-weight: bold;
               line-height: 1.1;
               margin: pxtorem(8) auto pxtorem(16);
          }
          a {
               color: $col-primary-2;
               margin-bottom: pxtorem(12);
               display: block;
          }
          padding: pxtorem(10);
          background-color: $col-blue;
          margin-bottom: pxtorem(32);
     }
     &__location {
          font-size: pxtorem(16);
     }
}
/*.myfilter img {

     filter: hue-rotate(52deg) saturate(1.29) sepia(0.82) ;

}

.myfilter{

     position: relative;

}

.myfilter:after{

     position: absolute; content: ''; display: block; top: 0; left: 0; height: 100%; width: 100%;

     background: linear-gradient(to left,rgba(73, 136, 255, 0.4) 0%, rgba(188, 188, 188, 0.4) 100%);

     mix-blend-mode: multiply

}*/
.footer {
     height: pxtorem(140);
     background-color: #16191b;
     display: flex;
     justify-content: center;
     margin-top: pxtorem(100);
     &__logo {
          background-image: url("../assets/GEMMA_LOGO_WHITE.svg");
          height: 72px;
          width: 72px;
          background-repeat: no-repeat;
          margin: auto;
          border: 1px solid #6d6d6d;
          border-radius: 50%;
          padding: 27px;
          background-size: 80%;
          background-position: center;
     }
}
.bg-col-blue {
     background-color: $col-blue;
}
.bg-col-blue-light {
     background-color: $col-blue-light;
}
.bg-col-yellow {
     background-color: $col-yellow;
}
.bg-col-white {
     background-color: $col-white;
}
.bg-col-pink {
     background-color: $col-pink;
}
.event {
     &__time {
          display: flex;
          font-weight: bold;
          p {
               margin-right: pxtorem(16);
          }
     }
}
.sidebar-calendar {
     h1 {
          color: #455d70;
     }
     &__entry {
          position: relative;
          padding: pxtorem(7);
          //border-bottom: 1px solid rgba(255, 255, 255, 0.6);
          margin-bottom: pxtorem(10);
          //background-color: rgba(255, 255, 255, 0.4);
          border-radius: pxtorem(5);
          background: linear-gradient(to bottom, #f2f2f5 0%,#ebecf2 100%);
          //box-shadow: 3px 3px 3px -3px rgba(0,0,0,0.44);
          b {
              /* padding-top: pxtorem(10);
               display: block;*/
          }
     }
     &__date {
          /*position: absolute;
          top: pxtorem(5);
          right: pxtorem(8);*/
          font-size: pxtorem(14);
          color: #441b48;
     }
}
.events {
     background-color: #f6f7f9;
     &__inner {
          flex-direction: column;
     }
     &__container {
          display: flex;
     }

     &__entry {
          padding: pxtorem(20);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
     }
     img {
          height: pxtorem(300);
          box-shadow: 3px 5px 5px -3px rgba(0,0,0,0.24);
     }
}
.calendar {
     display: flex;
     flex-wrap: wrap;

     &__entry {
          flex: 1 0 33%;
          height: pxtorem(200);
          background-color: $col-white;
          margin: 1px;
          padding: pxtorem(16);
     }
     &__title {
          font-weight: bold;
     }
}
.image_title {
     display: flex;

}
.ico {
     width: 24px;
     height: 24px;
     background-size: 70%;
     position: absolute;
     right: 0;
     transform: translateY(pxtorem(-14)) translateX(pxtorem(7));
     z-index: 1;
     background-repeat: no-repeat;
     background-position: center;
     background-color: #aebeca;
     border-radius: 50%;
     box-shadow: 3px 3px 3px -3px rgba(0,0,0,0.24);
     border: 1px solid #f6f0d5;
     &_baby {
          background-image: url("../assets/ico_baby.png");
     }
     &_studio {
          background-image: url("../assets/ico_studio.png");
     }
}

.contact {
     input, textarea {
          border: none;
          padding: 16px;
          margin-bottom: pxtorem(24);
     }
     textarea {
     }
     button[type=submit] {
          display: block;
          border: none;
          padding: 20px;
          background-color: #d06b96;
          color: #ffffff;
     }
}
