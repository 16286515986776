@use 'sass:math';
@import "vars";
* {
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4 {
  -webkit-font-smoothing: antialiased;
}



$breakpoints: (
        small : $bp-tablet,
        medium: $bp-desktop,
        large : $bp-desktop,
);
$body-font-sizes: (
        null: 12px,
        small: 13px,
        medium: 14px,
        large: 16px
);
@function calculateRem($size) {
  $remSize: ($size/16px);
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}
@mixin pxtorem($size) {
  font-size: calculateRem($size);
}
@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    }
    @else {
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (min-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    @include pxtorem(nth($fs-font-size, 1));
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  }
  @else {
    @include pxtorem($fs-font-size);
  }
}
html {
  @include font-size($body-font-sizes);
}
h3 {
  font-size: calculateRem(24px);
  margin: 0 0 calculateRem(4px);
  //color: #000000;
}
h4 {
  font-size: calculateRem(14px);
  margin: 0 0 calculateRem(4px);
  //color: #ffffff !important;
}
b {
  //font-weight: 700;
}
i {
  font-style: italic;
}
h1,h2,h3,h4,h5 {
  //color: $col-primary;
  font-family: "poiret-one", sans-serif;
}
.extrabold {
  font-family: "poiret-one", sans-serif;
}
body, p {
  font-family: 'Catamaran', Arial, Helvetica, 'Nimbus Sans L', sans-serif;
  font-size: pxtorem(18);
  line-height: 1.6;
  //color: $col-primary;
  strong {
    font-weight: bold;
  }
}
ul {
  margin-bottom: pxtorem(20px);
}
li {
  padding-left: 20px;
}
h1 {
  font-size: 1.5rem;
}
h1.big {
  font-size: pxtorem(42);
}
h4 {
  font-size: 1.5rem;
  line-height: 1.25;
  font-weight: bold;
}
p {
  line-height: 1.2;
  color: #676767;
  margin-bottom: pxtorem(20);
  //color: rgb(135, 138, 154);
}

p.sm {
  font-size: pxtorem(12px);
  line-height: 1.2;
}
b {
  font-weight: bold;
}
